import React from "react";
import TodoNavbar from "../components/TodoNavbar";

const TodoLayout = ({ children }) => {
    return (
        <>
            <TodoNavbar />
            <main>{children}</main>
        </>
    );
};

export default TodoLayout;
