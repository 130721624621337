import React from 'react';

function Contact() {
  return (
    <div className="container mt-3">
      <h3>Hubungi Saya</h3>
      <p className='text-justify'>
      Integer quis mauris risus. Praesent dictum, risus eu luctus sagittis, tortor ipsum auctor enim, at egestas metus turpis a ligula. In consectetur mauris ut leo maximus, quis rutrum dolor lobortis. Integer semper iaculis blandit. Donec gravida gravida aliquam. In dignissim sit amet tellus sed rhoncus. Nam suscipit interdum sem ac faucibus. Curabitur quam risus, condimentum eget tincidunt accumsan, ornare sed risus. Fusce id blandit turpis. Proin molestie ornare tortor, sed pellentesque quam condimentum a. Nam et massa quis nulla tempor fermentum. Morbi vulputate eros vel metus suscipit fermentum. Donec mi massa, egestas quis rutrum ut, mollis eleifend neque. Proin nec eros et sapien facilisis luctus nec a ante. Mauris laoreet, quam eget dictum maximus, lorem nibh finibus lacus, vel bibendum tortor tortor nec massa. Donec aliquam id leo eget ultrices.
      </p>
    </div>
  );
}

export default Contact;
