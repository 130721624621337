import React, { useState, useEffect, useRef } from "react";
import TodoForm from "./components/TodoForm";
import TodoList from "./components/TodoList";

const TodoPage = () => {
    const [todos, setTodos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        fetchTodos();
    }, []);

    const loadingRef = useRef(false);

    const fetchTodos = async () => {
        try {
            if (loadingRef.current) return; // Cegah fetch duplikat jika sedang loading
            loadingRef.current = true; // Set loading flag ke true
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/todo/get`);
            if (!response.ok) throw new Error("Failed to fetch todos");
            const data = await response.json();
            setTodos(data.data);
        } catch (error) {
            console.error("Error fetching todos:", error.message);
        } finally {
            loadingRef.current = false; // Reset loading flag setelah fetch selesai
            setLoading(false);
        }
    };

    const toggleComplete = async (id) => {
        try {
            setIsUpdating(true);
            const todo = todos.find((t) => t.id === id);
            if (!todo) throw new Error("Todo not found");

            const updatedTodo = { ...todo, selesai: !todo.selesai };
            const response = await fetch(`${process.env.REACT_APP_API_URL}/todo/update/${id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updatedTodo),
            });

            if (!response.ok) throw new Error("Failed to update todo");

            setTodos((prevTodos) =>
                prevTodos.map((t) =>
                    t.id === id ? { ...t, selesai: !t.selesai } : t
                )
            );
        } catch (error) {
            console.error("Error updating todo:", error.message);
        } finally {
            setIsUpdating(false);
        }
    };

    const deleteTodo = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/todo/delete/${id}`, {
                method: "DELETE",
            });
            if (!response.ok) throw new Error("Failed to delete todo");

            setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
        } catch (error) {
            console.error("Error deleting todo:", error.message);
        }
    };

    const addTodo = async (newTodo) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/todo/add`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newTodo),
            });
            if (!response.ok) throw new Error("Failed to add new todo");
            const result = await response.json();
            const addedTodo = {
                ...newTodo,
                id: result.id,
            };
            setTodos((prevTodos) => {
                return [addedTodo, ...prevTodos];
            });
        } catch (error) {
            console.error("Error adding todo:", error.message);
        }
    };

    return (
        <div className="container mt-3">
            <TodoForm addTodo={addTodo} />
            <h1 className="mt-2">To-Do List</h1>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Cari todo..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <TodoList
                    todos={todos}
                    toggleComplete={toggleComplete}
                    deleteTodo={deleteTodo}
                    searchQuery={searchQuery}
                />
            )}
        </div>
    );
};

export default TodoPage;
