import React from 'react';

function About() {
  return (
    <div className="container mt-3">
      <h3>About Me</h3>
        <p className='text-justify'>
          Halo! Saya seorang pengembang website yang memiliki hobi dalam dunia coding dan teknologi. Walaupun ngoding hanya sekadar hobi, saya memiliki pengalaman di berbagai bahasa pemrograman seperti HTML, PHP, MySQL, Java, JavaScript, Visual Basic, C++, Python dan React. Saya senang mengasah keterampilan saya dan mencoba hal-hal baru di bidang pengembangan web.
        </p>
      <h3 className="mt-3">Visi dan Tujuan</h3>
        <p className='text-justify'>
          Di waktu luang, saya menikmati bermain game sebagai cara untuk bersantai dan mengisi waktu. Bagi saya, game juga merupakan sumber inspirasi dan cara untuk memahami lebih dalam tentang desain dan pengalaman pengguna yang interaktif.
        </p>
        <div className='d-flex justify-content-between flex-wrap'>
          <img
            src={process.env.PUBLIC_URL + '/img/about/html.png'}
            className="img-fluid d-block p-1"
            alt="html"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
          <img
            src={process.env.PUBLIC_URL + '/img/about/php.png'}
            className="img-fluid d-block p-1"
            alt="php"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        <img
            src={process.env.PUBLIC_URL + '/img/about/mysql.png'}
            className="img-fluid d-block p-1"
            alt="mysql"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        <img
            src={process.env.PUBLIC_URL + '/img/about/java.png'}
            className="img-fluid d-block p-1"
            alt="java"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        <img
            src={process.env.PUBLIC_URL + '/img/about/js.png'}
            className="img-fluid d-block p-1"
            alt="js"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        <img
            src={process.env.PUBLIC_URL + '/img/about/vb.png'}
            className="img-fluid d-block p-1"
            alt="vb"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        <img
            src={process.env.PUBLIC_URL + '/img/about/c++.png'}
            className="img-fluid d-block p-1"
            alt="c++"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        <img
            src={process.env.PUBLIC_URL + '/img/about/python.png'}
            className="img-fluid d-block p-1"
            alt="python"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        <img
            src={process.env.PUBLIC_URL + '/img/about/react.png'}
            className="img-fluid d-block p-1"
            alt="react"
            style={{ maxHeight: '70px', objectFit: 'cover' }}
          />
        </div>
    </div>
  );
}

export default About;
