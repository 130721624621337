import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import TodoLayout from './layouts/TodoLayout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Project from './pages/Project';
import TodoPage from './pages/todo/TodoHome';
import DeletedTasks from './pages/todo/pages/TodoDeleted';

function App() {
    return (
        <Router basename="/">
            <RouteTracker /> {/* Tambahkan komponen untuk melacak rute */}
            <Routes>
                <Route path="/" element={<MainLayout><Home /></MainLayout>} />
                <Route path="/about" element={<MainLayout><About /></MainLayout>} />
                <Route path="/contact" element={<MainLayout><Contact /></MainLayout>} />
                <Route path="/project" element={<MainLayout><Project /></MainLayout>} />
                <Route
                    path="/todo/*"
                    element={
                        <TodoLayout>
                            <Routes>
                                <Route path="/" element={<TodoPage />} />
                                <Route path="/deleted" element={<DeletedTasks />} />
                            </Routes>
                        </TodoLayout>
                    }
                />
            </Routes>
        </Router>
    );
}

// Komponen untuk melacak rute sebelumnya
function RouteTracker() {
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const previousPath = sessionStorage.getItem('previousPage');
        
        // Jika rute sebelumnya bukan halaman To-Do, simpan ke sessionStorage
        if (!currentPath.startsWith('/todo')) {
            sessionStorage.setItem('previousPage', currentPath);
        }

    }, [location]);

    return null; // Tidak menampilkan apapun
}

export default App;
