import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const TodoNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const previousPage = sessionStorage.getItem('previousPage') || '/';

  const menuItems = useMemo(
    () => [
      { text: 'To-Do List', path: '/todo' },
      { text: 'Deleted Tasks', path: '/todo/deleted' },
    ],
    []
  );

  // Mengubah title berdasarkan rute saat ini
  useEffect(() => {
    const currentPath = location.pathname;
    const currentMenuItem = menuItems.find((item) => item.path === currentPath);
    if (currentMenuItem) {
      document.title = `${currentMenuItem.text} - Todo App`;
    }
  }, [location, menuItems]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          Todo App
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              Todo Menu
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              {menuItems.map((item, index) => (
                <li className="nav-item" key={index}>
                  <Link
                    className={`nav-link ${
                      location.pathname === item.path ? 'active' : ''
                    }`}
                    to={item.path}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
              <li className="nav-item">
                {/* Tombol Exit */}
                <button
                  className="btn btn-link nav-link"
                  onClick={() => navigate(previousPage)}
                  data-bs-dismiss="offcanvas"
                >
                  Exit
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TodoNavbar;
