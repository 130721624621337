import React, { useState } from "react";

const TodoForm = ({ addTodo }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // Default tanggal sekarang

    const handleSubmit = (e) => {
        e.preventDefault();
        if (title.trim() && date && description.trim()) {
            // Mengirim data dengan properti yang sesuai (judul, deskripsi, tanggal)
            addTodo({ judul: title, deskripsi: description, tanggal: date });
            setTitle("");
            setDescription("");
            setDate(new Date().toISOString().split("T")[0]);
        } else {
            // Tampilkan pesan jika ada field yang kosong
            alert("Semua field harus diisi!");
        }
    };

    return (
        <>
            {/* Tombol untuk membuka modal */}
            <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#todoModal"
            >
                Tambah Tugas
            </button>

            {/* Modal */}
            <div
                className="modal fade"
                id="todoModal"
                tabIndex="-1"
                aria-labelledby="todoModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="todoModalLabel">
                                Tambah Tugas Baru
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-floating mb-3">
                                    <input
                                        type="text"
                                        id="todo_judul"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="form-control"
                                        placeholder="Judul"
                                    />
                                    <label htmlFor="todo_judul">Judul</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea
                                        id="todo_deskripsi"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="form-control"
                                        placeholder="Deskripsi"
                                        style={{ height: "100px" }}
                                    ></textarea>
                                    <label htmlFor="todo_deskripsi">Deskripsi</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <input
                                        type="date"
                                        id="todo_tanggal"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        className="form-control"
                                    />
                                    <label htmlFor="todo_tanggal">Tanggal</label>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    data-bs-dismiss="modal"
                                >
                                    Tambah
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TodoForm;
