import React, { useState, useEffect, useRef } from "react";

// Fungsi untuk memformat tanggal
const formatDate = (dateString) => {
    const date = new Date(dateString);  // Membuat objek Date dari string
    const day = String(date.getDate()).padStart(2, '0');  // Ambil hari dengan 2 digit
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Ambil bulan dengan 2 digit (dimulai dari 0)
    const year = date.getFullYear();  // Ambil tahun
    return `${day}-${month}-${year}`;  // Formatkan tanggal menjadi DD-MM-YYYY
};

const TodoDeleted = () => {
    const [todos, setTodos] = useState([]);  // Menambahkan state untuk todos
    const [deletedTodos, setDeletedTodos] = useState([]);
    const [loading, setLoading] = useState(false);

    // Ref untuk mencegah fetch duplikat
    const loadingRef = useRef(false);

    // Ambil data todo yang dihapus dari backend
    const fetchDeletedTodos = async () => {
        if (loadingRef.current) return; // Cegah fetch duplikat jika sedang loading
        loadingRef.current = true; // Set loading flag ke true

        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/todo/get/deleted`);
            if (!response.ok) throw new Error("Failed to fetch deleted todos");
            const data = await response.json();
            setDeletedTodos(data.data);
        } catch (error) {
            console.error("Error fetching deleted todos:", error.message);
        } finally {
            loadingRef.current = false; // Reset loading flag setelah fetch selesai
            setLoading(false);
        }
    };

    // Fungsi untuk mengembalikan todo yang dihapus
    const restoreTodo = async (todo) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/todo/restore/${todo.id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            });

            if (!response.ok) throw new Error("Failed to restore todo");

            // Jika berhasil mengembalikan todo, hapus dari daftar deleted
            setDeletedTodos((prevDeletedTodos) =>
                prevDeletedTodos.filter((item) => item.id !== todo.id)
            );

            // Ambil ulang daftar todo aktif
            const restoredTodo = await response.json();
            setTodos((prevTodos) => [restoredTodo, ...prevTodos]);  // Menambahkan todo yang dipulihkan ke state todos
        } catch (error) {
            console.error("Error restoring todo:", error.message);
        }
    };

    // Mengambil data deleted todos saat komponen di-render
    useEffect(() => {
        fetchDeletedTodos();
    }, []);

    return (
        <div className="container mt-3">
            <h2>Deleted Todos</h2>
            {loading && <p>Loading deleted todos...</p>}
            <div
                style={{
                    overflowY: "auto",
                    height: "70vh",
                    scrollbarWidth: "none", /* For Firefox */
                    msOverflowStyle: "none", /* For Internet Explorer and Edge */
                }}
            >
                {deletedTodos.length > 0 ? (
                    deletedTodos.map((todo) => (
                        <div key={todo.id} className="todo-item position-relative py-2">
                            <div className="d-flex align-items-start">
                                <div>
                                    <h6
                                        className="mb-1"
                                        style={{ textDecoration: todo.selesai ? "line-through" : "none" }}
                                    >
                                        {todo.judul}
                                    </h6>
                                    <p className="mb-1 text-muted" style={{ fontSize: "0.9rem" }}>
                                        {todo.deskripsi}
                                    </p>
                                    <span className="text-muted" style={{ fontSize: "0.8rem" }}>
                                        {formatDate(todo.tanggal)} {/* Memformat tanggal */}
                                    </span>
                                </div>
                            </div>
                            <button
                                onClick={() => restoreTodo(todo)}
                                className="btn btn-sm btn-warning position-absolute top-50 end-0 translate-middle-y"
                                style={{ transition: "opacity 0.3s ease-in-out" }}
                            >
                                Restore
                            </button>
                            <hr />
                        </div>
                    ))
                ) : (
                    <p>No deleted todos found.</p>
                )}
            </div>
        </div>
    );
};

export default TodoDeleted;
