import React from "react";

const formatDate = (dateString) => {
    const date = new Date(dateString);  // Membuat objek Date dari string
    const day = String(date.getDate()).padStart(2, '0');  // Ambil hari dengan 2 digit
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Ambil bulan dengan 2 digit (dimulai dari 0)
    const year = date.getFullYear();  // Ambil tahun
    return `${day}-${month}-${year}`;  // Formatkan tanggal menjadi DD-MM-YYYY
};

const TodoItem = ({ todo, toggleComplete, deleteTodo }) => {
    return (
        <li className="todo-item position-relative py-2">
            <div className="d-flex align-items-start">
                <input
                    type="checkbox"
                    checked={todo.selesai}
                    onChange={() => toggleComplete(todo.id)}
                    className="form-check-input me-3 mt-1"
                />
                <div>
                    <h6
                        className="mb-1"
                        style={{ textDecoration: todo.selesai ? "line-through" : "none" }}
                    >
                        {todo.judul}
                    </h6>
                    <p className="mb-1 text-muted" style={{ fontSize: "0.9rem" }}>
                        {todo.deskripsi}
                    </p>
                    <span className="text-muted" style={{ fontSize: "0.8rem" }}>
                        {formatDate(todo.tanggal)}
                    </span>
                </div>
            </div>
            <button
                onClick={() => deleteTodo(todo.id)}
                className="btn btn-sm btn-danger position-absolute top-50 end-0 translate-middle-y"
                style={{ transition: "opacity 0.3s ease-in-out" }}
            >
                Hapus
            </button>
            <hr />
        </li>
    );
};

export default TodoItem;
