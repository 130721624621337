import React, { useState, useEffect, useRef } from 'react';

// Fungsi untuk memformat tanggal
const formatDate = (dateString) => {
  const date = new Date(dateString);  // Membuat objek Date dari string
  const day = String(date.getDate()).padStart(2, '0');  // Ambil hari dengan 2 digit
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Ambil bulan dengan 2 digit (dimulai dari 0)
  const year = date.getFullYear();  // Ambil tahun
  return `${day}-${month}-${year}`;  // Formatkan tanggal menjadi DD-MM-YYYY
};

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  // Ref untuk mencegah fetch duplikat
  const loadingRef = useRef(false);

  // Ambil data project dari backend
  const fetchProjects = async () => {
    if (loadingRef.current) return; // Cegah fetch duplikat jika sedang loading
    loadingRef.current = true; // Set loading flag ke true

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/project/get`);
      if (!response.ok) throw new Error('Failed to fetch projects');
      const data = await response.json();
      setProjects(data.data);
      
      // Simpan data di sessionStorage
      sessionStorage.setItem('projectsData', JSON.stringify(data.data));
    } catch (error) {
      console.error('Error fetching projects:', error.message);
    } finally {
      loadingRef.current = false; // Reset loading flag setelah fetch selesai
      setLoading(false);
    }
  };

  // Mengambil data project saat komponen di-render
  useEffect(() => {
    // Cek jika data sudah ada di sessionStorage
    const storedProjects = sessionStorage.getItem('projectsData');
    if (storedProjects) {
      setProjects(JSON.parse(storedProjects)); // Gunakan data yang sudah ada
    } else {
      fetchProjects(); // Fetch data jika tidak ada di sessionStorage
    }
  }, []);

  return (
    <div className="container mt-3">
      <h3>List of Projects</h3>
      {loading && <p>Loading projects...</p>}
      <div className="row">
        {projects.length > 0 ? (
          projects.map((project) => (
            <div key={project.id} className="col-md-4 mb-4">
              <div className="card">
                <img
                  src={project.img_pro}
                  alt={project.jud_pro}
                  className="card-img-top"
                />
                <div className="card-body">
                  <h5 className="card-title">{project.jud_pro}</h5>
                  <p className="card-text text-justify">{project.des_pro}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="text-muted" style={{ fontSize: '0.8rem' }}>
                      {formatDate(project.tgl_pro)} {/* Memformat tanggal */}
                    </span>
                    <a href={project.link_pro} className="btn btn-sm btn-primary">
                      Go to Project
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No projects found.</p>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
