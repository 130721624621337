import React, { useState } from "react";
import TodoItem from "./TodoItem";

const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

const TodoList = ({ todos, deleteTodo, toggleComplete, searchQuery }) => {
    const today = formatDate(new Date());
    const [filterFromDate, setFilterFromDate] = useState(today);
    const [filterToDate, setFilterToDate] = useState(today);
    const [filterStatus, setFilterStatus] = useState("notCompleted");

    const formattedTodos = todos.map((todo) => ({
        ...todo,
        tanggal: formatDate(todo.tanggal),
    }));

    const filteredTodos = formattedTodos.filter((todo) => {
        const dateMatch =
            (filterFromDate && filterToDate)
                ? todo.tanggal >= filterFromDate && todo.tanggal <= filterToDate
                : true;
        const statusMatch =
            filterStatus === "completed"
                ? todo.selesai
                : filterStatus === "notCompleted"
                ? !todo.selesai
                : true;
        const searchMatch =
            (todo.judul?.toLowerCase() || "").includes(searchQuery?.toLowerCase() || "") ||
            (todo.deskripsi?.toLowerCase() || "").includes(searchQuery?.toLowerCase() || "");
        return dateMatch && statusMatch && searchMatch;
    });

    return (
        <div>
            <div className="d-flex mb-3">
                <input
                    type="date"
                    className="form-control me-2"
                    value={filterFromDate}
                    onChange={(e) => setFilterFromDate(e.target.value)}
                />
                <span className="mx-2">to</span>
                <input
                    type="date"
                    className="form-control me-2"
                    value={filterToDate}
                    onChange={(e) => setFilterToDate(e.target.value)}
                />
                <select
                    className="form-select"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                >
                    <option value="">Semua</option>
                    <option value="completed">Selesai</option>
                    <option value="notCompleted">Belum Selesai</option>
                </select>
            </div>

            {/* Kontainer scrollable */}
            <div
                style={{
                    overflowY: "auto",
                    height: "60vh",
                    scrollbarWidth: "none", /* For Firefox */
                    msOverflowStyle: "none", /* For Internet Explorer and Edge */
                }}
            >
                <ul className="list-unstyled">
                    {filteredTodos.map((todo) => (
                        <TodoItem
                            key={todo.id}
                            todo={todo}
                            toggleComplete={toggleComplete}
                            deleteTodo={deleteTodo}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TodoList;
